import { Pipe, PipeTransform } from "@angular/core";
import { DATA_FORMAT_FLAGS } from "src/app/constants/constants";


@Pipe({
    name: 'dataFormattingPipe'
})

export class dataFormatPipe implements PipeTransform {
    constructor() { }

    public transform(pNumber: any, col: string, pUnitsFlag: any, dataFormattingColumns?: any, removeParentsForNegativeVal?: any, printZeros?: any) {
        if (pNumber === "0" || pNumber === 0 || pNumber === "NULL") {
            if (printZeros) {
                return pNumber
            } else {
                return '';
            }
        }
        let functionReturnValue = '';
        if (["T_CHANGE", "C_CHANGE", "DIST_YTD_PCT_CHG", "REG_YTD_PCT_CHG", "NAT_YTD_PCT_CHG", "MTD_PCTCHG", "DLR_YTD_PCT_CHG"].includes(col)) {
            functionReturnValue = pNumber + '%';
        } else if (["MONTHCHANGE", "YEARCHANGE"].includes(col)) {
            functionReturnValue = this.formatNumber(pNumber, 1, -1) + "%";
        } else if ((!isNaN(pNumber) && (dataFormattingColumns?.includes(col) || dataFormattingColumns === 'all'))) {    // Need to remove the unitFlag from condition & Check weather if it is string or number?

            if (pNumber !== null && pNumber !== '') {
                switch (pUnitsFlag) {
                    case DATA_FORMAT_FLAGS.DollarWithZeroDecimal:
                        //dollar w/ 0 decimals
                        functionReturnValue = removeParentsForNegativeVal ? this.formatNumber(pNumber, 0, -1, true, removeParentsForNegativeVal) : '$' + this.formatNumber(pNumber, 0, -1, true, removeParentsForNegativeVal);
                        break;
                    case DATA_FORMAT_FLAGS.PercentWithOneDecimal:
                        //percent w/ 1 decimal
                        functionReturnValue = this.formatNumber(pNumber, 1, -1) + "%";
                        break;
                    case DATA_FORMAT_FLAGS.UnitsWithOneDecimal:
                        //units w/ 1 decimal
                        functionReturnValue = this.formatNumber(pNumber, 1, -1);
                        break;
                    case DATA_FORMAT_FLAGS.UnitsWithZeroDecimal:
                        //units w/ 0 decimal
                        functionReturnValue = this.formatNumber(pNumber, 0, -1, true);
                        break;
                    case DATA_FORMAT_FLAGS.RatioWithTwoDecimal:
                        //ratio w/ 2 decimal
                        functionReturnValue = this.formatNumber(pNumber, 2, -1) + ":1";
                        break;
                    case DATA_FORMAT_FLAGS.UnitsWithTwoDecimal:
                        //units w/ 2 decimal
                        functionReturnValue = this.formatNumber(pNumber, 2, -1);
                        break;
                    case DATA_FORMAT_FLAGS.PercentWithZeroDecimal:
                        //percent w/ 0 decimal
                        functionReturnValue = this.formatNumber(pNumber, 0, -1) + "%";
                        break;
                    case DATA_FORMAT_FLAGS.DollarWithTwoDecimal:
                        //dollar w/ 2 decimals
                        functionReturnValue = removeParentsForNegativeVal ? this.formatNumber(pNumber, 2, -1, true, removeParentsForNegativeVal) : '$' + this.formatNumber(pNumber, 2, -1, true, removeParentsForNegativeVal);
                        break;
                    case DATA_FORMAT_FLAGS.PercentWithTwoDecimal:
                        //percent w/ 2 decimal  
                        functionReturnValue = this.formatNumber(pNumber, 2, -1) + "%";
                        break;
                    case DATA_FORMAT_FLAGS.ValueWithYesORNo:
                        //Yes/No   
                        functionReturnValue = pNumber == "1" ? "Yes" : "No";
                        break;
                    case DATA_FORMAT_FLAGS.RatioWithOneDecimal:
                        //ratio w/ 1 decimal  0 if 0
                        if (pNumber != "0") {
                            functionReturnValue = this.formatNumber(pNumber, 1, -1) + ":1";
                        }
                        else {
                            functionReturnValue = "0";
                        }

                        break;
                    default:
                        functionReturnValue = this.formatNumber(pNumber, 0, -1);
                        break;

                }
            }
        }

        else {
            functionReturnValue = pNumber;
        }
        return functionReturnValue;
    }

    private formatNumber(sNumber: number, NumDigitsAfterDecimal, UseParensForNegativeNumbers, isDollarVal?, removeParentsForNegativeVal?) {
        let sRtnValue;
        if (sNumber !== null) {
            const value = parseFloat(sNumber.toString()).toFixed(NumDigitsAfterDecimal);
            if (isDollarVal) {
                sRtnValue = Number(value).toLocaleString();
            } else {
                sRtnValue = value;
            }

            if (removeParentsForNegativeVal) {
                sRtnValue = (sNumber < 0) ? sRtnValue.replace("-", "-$") : sRtnValue;

            } else {
                sRtnValue = UseParensForNegativeNumbers == -1 ? ((sNumber < 0) ? "(" + sRtnValue.replace("-", "").trim() + ")" : sRtnValue) : sRtnValue;
            }
        }
        return sRtnValue;
    }

}
