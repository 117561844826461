<table [id]="'pdfTable-' + tableIndex" #pdfTable mat-table [dataSource]="multiHeaderTabledataSource"
  aria-describedby="multiheader table"
  *ngIf="multiHeaderTableData.datasourceName !== 'tcuvfinance' && multiHeaderTableData.datasourceName != 'skill_set' && multiHeaderTableData.datasourceName !== 'parts_data' && multiHeaderTableData.datasourceName !== 'parts_access_data'"
  class="mat-elevation-z8 "
  [ngClass]="isRegionalTable ? 'dxfw-ytd-mtd-cell-class' : (alternateRowColorClass? alternateRowColorClass : (isMatTableClass ? '' : 'dxfw-alt-wht-gry-class'))">

  <!-- to display columns if we get it from BE else We have defined columns -->
  <ng-container [matColumnDef]="col" *ngFor="let col of multiHeaderdisplayedColumns; let i=index">
    <ng-container *ngIf="displayColumnName?.length > 0">
      <th mat-header-cell *matHeaderCellDef class="dxfw-custom-report-table-header"
        [ngClass]="[showBorder(col, i),alignLeftColumnsforTH(i),tableSubHeaderWhiteBGColorClass ? tableSubHeaderWhiteBGColorClass : '']"
        [attr.colspan]="1">
        {{displayCustomizedHeaderName(displayColumnName[i]) }}</th>
    </ng-container>
    <ng-container *ngIf="displayColumnName?.length === 0 ">
      <th mat-header-cell *matHeaderCellDef class="dxfw-custom-report-table-header"
        [ngClass]="[showBorder(col, i),alignLeftColumnsforTH(i), col==='DEADLINES_EMPTY'? 'dxfw-dealer-review-white-header-col':'',whiteBorderClass ? whiteBorderClass : '']"
        [attr.colspan]="1">
        {{displayCustomizedHeaderName(col)}}
      </th>
      <span></span>
    </ng-container>

    <td mat-cell *matCellDef="let element; let a= index;"
      [style.backgroundColor]="col === 'MEASURE_EMPTY' || col === 'DEALERSHIP_MTD_EMPTY' || col ==='KEY_METRIC_EMPTY' ? element.FONTCOLOR : ''"
      [ngClass]="[i === 0 ? tableColumnClass :'dxfw-custom-report-table-cell-center', alignLeftColumnsforTD(i),
        checkNullValOfGPColumm(col,element) ?'dxfw-custom-report-table-cell-bgcolor':'',showBorder(col, i),whiteBorderClass ? whiteBorderClass : ''
      ]">
      <span [ngClass]="{'dxfw-custom-report-table-cell-highlightcolor': checkDifferenceColValue(col, element)}"
        [style.color]="col === 'CMVALUE' || col === 'MEASURE_EMPTY' ? element.FONTCOLOR : ''">{{element[col] |
        dataFormattingPipe: col : defaultUnitFlag ?
        defaultUnitFlag : element['UNITS_FLAG'] : dataFormattingColumns : removeParentsForNegativeVal : printZeros
        }}</span>

      <span *ngIf="multiHeaderTableData?.showLineNumbers && i== 0">{{ element['ROW']? element['ROW'] :a+1}}</span>

      <span *ngIf="displayingInput(col, element)">
        <mat-form-field class="dxfw-part-detparment-report-input">
          <input matInput placeholder="" value="">
        </mat-form-field>
      </span>

    </td>
  </ng-container>

  <ng-container *ngIf="tableSubHeaderColumns?.length > 0 && subHeaderColumns?.length > 0">
    <ng-container [matColumnDef]="col" *ngFor="let col of obj(subHeaderColumns[0]); let i=index">
      <th mat-header-cell class="dxfw-custom-report-table-header"
        [ngClass]="[alignLeftColumnsforTH(i),tableSubHeaderBGColorClass ? tableSubHeaderBGColorClass : '']"
        *matHeaderCellDef [attr.colspan]="subHeaderColumns[0][col]">{{col}}</th>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="tableSubHeaderColumns2?.length > 0 && subHeaderColumns2?.length > 0">
    <ng-container [matColumnDef]="col" *ngFor="let col of obj(subHeaderColumns2[0])">
      <th mat-header-cell class="dxfw-custom-report-table-header" *matHeaderCellDef
        [attr.colspan]="subHeaderColumns2[0][col]">{{col}}</th>
    </ng-container>
  </ng-container>

  <!-- to display the header -->
  <ng-container *ngIf="tableFirstHeaderColumns?.length > 0">
    <ng-container [matColumnDef]="col" *ngFor="let col of tableFirstHeaderColumns; let i = index">
      <ng-container *ngFor="let keyvaluepair of tableFirstHeader ">
        <th mat-header-cell class="dxfw-custom-report-table-header"
          [ngClass]="[(tableHeaderClass && (i===1 && showEmptyCol)) ? 'dxfw-dealer-review-white-header-col' : (tableHeaderClass ? tableHeaderClass : ''),alignLeftColumnsforTH(i)]"
          *matHeaderCellDef [attr.colspan]="keyvaluepair[col]"> {{displayCustomizedFirstHeader(col)}}</th>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="tableFirstHeaderColumns?.length >0">
    <tr mat-header-row *matHeaderRowDef="tableFirstHeaderColumns"></tr>
  </ng-container>

  <ng-container *ngIf="tableSubHeaderColumns?.length >0">
    <tr mat-header-row *matHeaderRowDef="tableSubHeaderColumns"></tr>
  </ng-container>
  <ng-container *ngIf="tableSubHeaderColumns2?.length >0">
    <tr mat-header-row *matHeaderRowDef="tableSubHeaderColumns2"></tr>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="multiHeaderdisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: multiHeaderdisplayedColumns;" [ngClass]="showBorderBottom(row)"></tr>
</table>


<div *ngIf="!multiHeaderTableData?.removeLineBreak">
  <br />
</div>


<div class="table-container"
  *ngIf="multiHeaderTableData.datasourceName === 'tcuvfinance' || multiHeaderTableData.datasourceName === 'parts_access_data' || multiHeaderTableData.datasourceName === 'parts_data' || multiHeaderTableData.datasourceName === 'skill_set'">
  <table aria-describedby="profile table">
    <thead>
      <tr *ngFor="let col of tableFirstHeaderColumns let i=index">
        <th [ngClass]="['dxfw-tcuv-header', alignLeftColumnsforTH(i)]" id="mainheader" colspan="10">{{col}}</th>
      </tr>
      <tr>
        <ng-container *ngIf="subHeaderColumns?.length > 0">
          <ng-container *ngFor="let cols of obj(subHeaderColumns[0])">
            <th class="dxfw-custom-report-table-header" [attr.colspan]="subHeaderColumns[0][cols]">{{cols}}</th>
          </ng-container>
        </ng-container>
      </tr>
      <tr>
        <th class="dxfw-custom-report-table-header" id="header" *ngFor="let columnName of displayColumnName">{{
          columnName }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="mat-cell dxfw-custom-report-table-cell-left-align sectionttlClass" colspan="10">{{ sectionTitle}}
        </td>
      </tr>

      <ng-container *ngFor="let item of multiHeaderTabledataSource?.data">
        <tr>
          <td class="mat-cell " *ngFor="let val of multiHeaderdisplayedColumns; let i =index;"
            [ngClass]="i == 0? 'widthClass' : 'dxfw-custom-report-table-cell-center'">
            <span [ngClass]="{'dxfw-custom-report-table-cell-highlightcolor': checkDifferenceColValue(val, item)}"
              [style.color]="val === 'CMVALUE' || val === 'MEASURE_EMPTY' ? item.FONTCOLOR : ''">{{item[val]
              === "0" || item[val] === "null" ? '' : (item[val] | dataFormattingPipe
              : val : defaultUnitFlag ?
              defaultUnitFlag : item['UNITS_FLAG'] : dataFormattingColumns :removeParentsForNegativeVal)}}</span>
          </td>
        </tr>
      </ng-container>

      <tr>
        <td *ngIf="allGuidlinesMet" class="mat-cell dxfw-custom-report-table-cell-left-align guideLinesClass"
          colspan="10">{{allGuidlinesMet}}
        </td>
      </tr>
    </tbody>
  </table>
</div>