import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DATA_FORMAT_FLAGS, HIERARCHY_TYPE, reportId, reportUrlType, REPORT_MSGS } from 'src/app/constants/constants';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';

@Component({
  selector: 'app-dealer-review',
  templateUrl: './dealer-review.component.html',
  styleUrls: ['./dealer-review.component.scss']
})
export class DealerReviewComponent implements OnInit {

  public dealerReviewjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: string;
  public reportTitle: string;
  public reportTitleToDisplay: string;
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  public obj = Object.values;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();

    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-list'));
    this.reportTitle = reportList?.[this.reportId]?.name;
    this.getPartsServiceAndSalesAnalysis();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource(selectedFilter: any) {
    const obj = getObjFromFilteredArray(selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const viewId = this.getViewId(obj['company']);
    const filterObj = obj['dealer'];
    const requestObj = {
      month: dateObj.searchMonth,
      dealerno: obj['dealer'].MEMBER_CODE,
      view_id: 'dealer_review',
      year: dateObj.searchYear,
    }

    if (obj['hierarchy']?.id === HIERARCHY_TYPE.DEALER) {
      this.sources = [this.customReportsApiService.getDealerReviewData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
      ]
    } else {
      this.sources = [this.customReportsApiService.getEntityDealerReviewData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
      ]
    }

    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (selectedFilter) => {
          if (selectedFilter?.length > 0) {
            this.selectedFilteredValues = selectedFilter;
            this.spinnerService.displaySpinner();
            this.mapResponse(selectedFilter);
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
    this.customerReportService.setPdfExcelIcons(false);
  }

  public getPartsServiceAndSalesAnalysis() {
    this.dealerReviewjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse(selectedFilter: any) {
    let currentTableResponse = [];
    await this.getTabDataSource(selectedFilter);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].body) {
          this.customerReportService.setPdfExcelIcons(true);
          resp[0].body.forEach((element) => {
            let reportData = element.reportData;
            let subHeaderCode = element.reportHeaderData[0].REGION_CODE;
            if (reportData?.length > 0) {
              reportData.forEach((ele, index) => {

                if (index < 4) {
                  ele.forEach((section, i) => {
                    if (section.sectionData?.length > 0) {
                      const obj = {
                        'datasourceName': `dealerReview ${index}`,
                        'tableData': section.sectionData,
                        'sectionTitle': section.SECTIONTITLE,
                        'sectionId': section.SECTIONID,
                        'removeLineBreak': true,
                        'showLineNumbers': false,
                        'dataFormattingColumns': 'all',
                        'subHeaderForYearTag': (index === 1 || index === 3) ? `REGION ${subHeaderCode}` : 'YEAR',
                        "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                        "removeParentsForNegativeVal": true,
                        'alternateRowColorClass': (index === 1 || index === 3) ? 'dxfw-alt-wht-pink-class' : '',
                        'isMatTableClass': false,
                        'showEmptyCol': true,
                        'showNegativeInRed': true,
                        'whiteBorderClass': (index === 1 || index === 3) ? 'dxfw-dealer-review-table-border' : '',
                      };
                      if (index === 0 && section.SECTIONID === "1") {
                        const header = 'SALES';
                        let dealerReviewReportHeader = { DealerReview: [{ [header]: '20' }] };
                        obj['tableHeader'] = dealerReviewReportHeader.DealerReview;
                        obj["tableHeaderClass"] = 'dxfw-dealer-review-header';
                      } else if (index === 2 && section.SECTIONID === "2") {
                        const header = 'SERVICES';
                        let dealerReviewReportHeader = { DealerReview: [{ [header]: '20' }] };
                        obj['tableHeader'] = dealerReviewReportHeader.DealerReview;
                        obj["tableHeaderClass"] = 'dxfw-dealer-review-header';
                      }

                      currentTableResponse.push(obj);
                    }
                  })
                }
              });
            } else {
              this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
            }
          })

        }
        this.dealerReviewjsonObj.datasource = currentTableResponse;
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }


  public getViewId(company) {
    const companyName = company?.name.toLowerCase();
    return `${this.reportTitle.replace(/\s+/g, '_').toLowerCase()}_${companyName}`;
  }

  public createPayload(searchMonth, region, district, searchYear, dealer, viewId) {
    const requestObj = {
      month: searchMonth,
      region_no: region,
      district_cd: district,
      year: searchYear,
      dealer_no: dealer,
      view_id: viewId
    }
    return requestObj;
  }

}
